/* openwidget.css */
.widgetButton {
    right: 40px;
}
.edvz03i2 {
    bottom: 20px;
    right:20px;
    border-radius:16px;
}

h1 {
    padding: 0 20px;
}

.rs-panel-body {
    padding:0;
}

.e1ujklng0 {
    max-height:600px;
}

.hover-list-item {
    background-color: white;
    transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, box-shadow; /* Helps optimize performance */
  }
  
  .hover-list-item:hover {
    box-shadow: 0 0px 10px rgba(201, 6, 6, 0.15);
  }

  .rs-scroll-view.rs-scroll-view-custom-scrollbar {
    height:100%
  }

  
/* Mobile styling */
@media (max-width: 340px) {
    .widgetFooter {
        max-width: 300px !important;
        min-width: 300px !important;
    }
    .virtuaali-apuri-widget .backgroundImage {
        margin-left: 120px;
    }
}
@media (max-width: 600px), (max-height: 500px) {
    .edvz03i2 {
        bottom: 0px;
        right: 0px;
        border-radius:0px;
    }
    .widgetButton {
        right: 20px;
    }
    .e1ujklng0 {
        max-height:1000px;
    }
    .maincontianer {
        max-width: 1000px !important;
        border:none;
    }
}


@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.widgetButton {
    transform-origin: center center;
}

@keyframes bubblePop {
    0% {
        transform: scale(0.8);
        opacity: 0;
        visibility: hidden;
    }
    1% {
        visibility: visible;
    }
    70% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes bubbleFloat {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.attention-bubble {
    animation: bubblePop 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both,
               bubbleFloat 3s ease-in-out infinite;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.message-container {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.message-container::-webkit-scrollbar {
    width: 6px;
}

.message-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%; /* Start position */
    }
    50% {
        background-position: 100% 50%; /* Middle position */
    }
    100% {
        background-position: 0% 50%; /* End position (loop back) */
    }
}



.faqContent img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
}

/* Optional: Add subtle zoom indication on hover */
.faqContent img:hover {
    position: relative;
}

.faqContent img:hover::after {
    content: '🔍';
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0,0,0,0.5);
    color: white;
    padding: 5px;
    border-radius: 4px;
}